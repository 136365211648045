import React from 'react'
import '../../css/inputs.css';
import {customer} from '../../customer/Login'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RequestListTable from './RequestListTable';
import RequestListTableLimit from './RequestListTableLimit';

const PersonalReqTable = ({limit}) => {
    const navigate = useNavigate();
    return (<>
    {
        limit? (<RequestListTableLimit limit={limit} customer={customer} tableTitle = "Individual Request View Table"/>): (
        customer? (<RequestListTable customer={customer} tableTitle = "Individual Request View Table" />):
        (<section className='section' >
            <h2 
                style={{backgroundColor:'InfoText',
                border: '2px solid InfoText',
                borderRadius:'0.5rem'}}
            >
                Can not Access your Identity !! <br/> Please Login First. {" "}
                <Button onClick={()=>navigate('/customer/login')}>Go Login</Button>
            </h2>
        </section>))
    }
    </>)
}

export default PersonalReqTable