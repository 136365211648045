import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap';
import '../css/inputs.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../Constants';
// import pp from '../images/oct2021.jpg';
// import altpp from '../images/photo_2021_03_11_13_27_56_de34bc0e31_cd79f3c1ac.jpg';
import PersonalReqTable from '../request/reqTables/PersonalReqTable';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import LogOutButton from '../LogOutButton';
// import Navbar from '../Navbar';
// import {banksOption} from '../request/Request';

const CustomerProfile = ({customer}) => {

  const navigate = useNavigate();
  const [update, setUpdate] = React.useState(customer)
  
  useEffect(()=> {
    axios.get(API_URL + `/customer/findbyid/${customer._id}`)
    .then((res) => setUpdate(res.data.customer))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    .catch((err) => err)}, []);

const {firstName, middleName, lastName, phoneNumber,banks, requests,} = update;
  const handleAddAccount = () => {
    // const banks = '<select type="text" id="committed-bank">{banksOption.map((option) => (<option key={option.id} value={option.value}> {option.label} </option>" +"))} </select>';
    const bank = prompt("Bank: ");
    if(bank) {
      const accNum  = prompt("Account Number: ");
      if(bank && accNum)
        axios.put(API_URL + `/customer/addbank/${customer._id}`, { bank, accNum })
        .then((res) => alert("Successfully updated !!"))
        .catch((error) => alert(error))
      else alert("Insufficient data")
    }
    else alert("Insufficient data")
  }
  return (
    <section style={{width:'auto', border:'2px solid aliceblue', borderRadius:'0.75rem', backgroundColor:'aliceblue'}}>
      {/* <Navbar /> */}
      <div 
        className='customerProfileDiv' 
        style={{display:'flex', flexDirection: 'row', width: "auto", height:"auto"}}>
        <div>
          <label htmlFor='name'>Name: {firstName + " " + middleName + " " + lastName}</label><br/>
          <label htmlFor='phoneNumber'>Phone Number:  {phoneNumber}</label><br/>
          <label htmlFor='requests'> Banks: {banks.length } {banks.length >1? "banks": "bank"}</label> <br/>
          <label htmlFor='seeRequests'>Requests: {requests.length} {requests.length >1? "requests": "request"} </label><br/>
          <div>
            <Button className='buttons' onClick={()=> navigate('/customer/edit/')}>Edit Profile</Button>
            <Button className='buttons' onClick={()=>navigate('/request/my-requests')}> My Requests </Button>
            <Button className='buttons' onClick={()=> handleAddAccount()}> Add Bank </Button>
          </div>
          {/* <Button className='buttons' onClick={fetchUpdated}> Refresh </Button> */}
        </div>
        <div style={{width:"17rem", height: "auto",}}>
          <img src={PortraitOutlinedIcon} alt="profile pic" width="175rem" height= "175rem"/>
        </div>
        <div>
          <LogOutButton />
        </div>
      </div>
      <div id='request-view-area' className='customerProfileDiv'>
        <PersonalReqTable limit={true} />

        table here limit 3
      </div>

      <div style={{width:'40rem', height: 'auto%', margin : '0.5rem', padding: '0.5rem',
        backgroundColor:'violet', border:'2px solid gray', borderRadius:'0.75rem'}}>
        {/* <ImageDisplayer /> */}
      </div>

      <div>
        <h2>This part is for admins</h2>
        <Button className='buttons' onClick={()=>navigate('/customer/list')}>Customer list</Button>
        <Button className='buttons' onClick={()=>navigate('/request/list')}> Request list</Button>
      </div>
    </section>
  )
}

export default CustomerProfile