import React from 'react'
import useAxios from 'axios-hooks';
import CustList from './CustList'
import { Box, CircularProgress } from '@mui/material';
import { API_URL } from '../../Constants';

const CustomerListTable = () => {
  const [{ data, loading, error }] = useAxios(API_URL + "/customer/list")
  if(loading) return(<Box sx={{display:'flex'}}> <CircularProgress/> </Box>)
  if(error) return (<Box sx={{display:'flex'}}> <CircularProgress/> </Box>)

  const headCells = [
    // { id: 'oid', numeric: false, disablePadding: true, label: 'O_id (MongoDB determined)' },
    { id: 'rollNo', numeric: false, disablePadding: true, label: 'No:' },
    { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name' },
    { id: 'middleName', numeric: false, disablePadding: false, label: 'Middle Name' },
    { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name' },
    { id: 'phoneNumber', numeric: false, disablePadding: false, label: 'Tel' },
    { id: 'regxnDate', numeric: false, disablePadding: false, label: 'Rgxn Date' },
    { id: 'requests', numeric: false, disablePadding: false, label: 'Total Requests' },
  ];

  return (
    <CustList data = {data.message} headCells={headCells} tableTitle= "Customer Information Table" />
  )
}

export default CustomerListTable