import React from 'react'
import { Button } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

const LogOutButton = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button className='buttons' onClick={(e) => {navigate("/customer/login"); window.location.reload()}}> Log Out </Button>
    </>
  )
}

export default LogOutButton