import React from 'react'
import { Button } from 'react-bootstrap';
import {useNavigate } from 'react-router-dom';
import '../css/inputs.css'
import CustomerProfile from './CustomerProfile';
import {customer} from './Login'

const CustomerPage = () => {
  const navigate = useNavigate();
  return (
  <section className='section'>
  {
    !customer? (
    <h2 
      style={{backgroundColor:'InfoText',
      border: '2px solid InfoText',
      borderRadius:'0.5rem'}}
    >
      Can not Access your Identity !! <br/> Please Login First. {" "}
      <Button onClick={()=>navigate('/customer/login')}>Go Login</Button>
    </h2>):
    (<CustomerProfile customer={customer}/>)
  }
  </section>
    
  )
}

export default CustomerPage