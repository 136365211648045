import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './components/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Signup from './components/customer/Signup';
import Login from './components/customer/Login';
// import Request from './components/request/Request';
// import Search from './components/customer/search';
// import RequestMaterial from './components/RequestMaterial-UI'
// import ReactTable from './components/customer/tables/ReactTable';
// import Request from './components/request/Request';
import CustomerListTable from './components/customer/cust-table/CustomerListTable';
import PersonalReqTable from './components/request/reqTables/PersonalReqTable';
import RequestAddLayer from './components/request/RequestAddLayer';
import CustomerEditLayer from './components/customer/CustomerEditLayer';
import RequestEditLayer from './components/request/RequestEditLayer';
import CustomerPage from './components/customer/CustomerPage';
import RequestListTable from './components/request/reqTables/RequestListTable';

// import Ads1 from './components/ads/Ads1';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
      <BrowserRouter>
       <Routes>
          <Route path= '/' element= { <App/> }/>
          <Route path= '/customer/signup' element= { <Signup /> }/>
          <Route path= '/customer' element= { <CustomerPage /> }/>
          <Route path= '/customer/login' element= { <Login /> }/>
          <Route path= '/customer/edit/' element= { <CustomerEditLayer /> }/>
          <Route path= '/customer/list' element= { <CustomerListTable /> }/>

          {/* <Route path= '/customer/signup2' element= { <Signup2 /> }/> */}
          {/* <Route path= '/customer/signupmat' element= { <SignupMat /> }/> */}
          {/* <Route path= '/customer/signupm' element= { <Ads1 /> }/> */}


          {/*<Route path= '/customer/login2' element= { <Login2 /> }/>*/}
          {/* <Route path= '/customer/:email' element= { <Search /> }/> */}
          
          {/* <Route path= '/customer/enhanced-table' element= { <EnhancedTable /> }/> */}
          {/* <Route path= '/customer/reacttable' element= { <ReactTable /> }/> */}
          


          {/* <Route path= '/request/material-ui' element= { <RequestMaterial /> }/>*/}
          {/* <Route path= '/request/m' element= { <Request /> }/> */}


          <Route path= '/request/post' element={ <RequestAddLayer /> }/>
          <Route path= '/request/list' element={ <RequestListTable tableTitle = "Overall Request View Table" /> }/> 
          <Route path= '/request/edit' element={ <RequestEditLayer /> }/> 
          <Route path= '/request/my-requests' element={ <PersonalReqTable /> }/> 
          
       </Routes>
    </BrowserRouter>

   </React.StrictMode>
  );




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
