
import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import { Button} from 'react-bootstrap';
import BackButton from '../BackButton';

import "../css/inputs.css";
import { API_URL } from '../Constants'
// import Ads1 from "../ads/Ads1";

// const NAME_REGEX = /^[A-z]$/;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Signup = () => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pwd, setPwd] = useState("");
    const [matchPwd, setMatchPwd] = useState("");

    function handlePwds (e){

        if(firstName&&middleName&&lastName&&phoneNumber&&pwd&&matchPwd&&(pwd === matchPwd)) 
            handleSubmit(e)
        else alert(" some fields are empty or the passwords are different!!")
    }
    function handleSubmit(e){
        e.preventDefault(e); //editting goto customer-route
        axios.post(API_URL + '/customer/signup', { firstName, middleName, lastName, phoneNumber, pwd })
        .then((res) => {
            if(res.data.message){
                alert("WELCOME "+res.data.message.firstName+" "+res.data.message.middleName+" !");
                setFirstName(""); setLastName(""); setMiddleName("");setPwd(""); setMatchPwd(""); 
                setPhoneNumber("");
                navigate("/customer/login");
            }
            else alert(res.data.message)
            
        }).catch((err) => alert(err.response.data.message))
    }


    return(

        <section className="section">
            {/* <Ads1 /> */}
            <section>
            <Button className= 'buttons' onClick = {(e) => { navigate('/customer/login') }}>
                <i>already have one? </i> <b>Login</b>
            </Button>

            <form className="form">
                <h4 className="formh4">
                    Registration Form
                </h4>
                <label className="labelmargin" htmlFor="firstName">First Name: </label>
                <input
                    className="inputs"
                    type = {"text"}
                    id={"firstName"}
                    label = {"First Name"}
                    placeholder = {"First Name"}
                    variant = {"outlined"}
                    value = {firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            
                <label className="labelmargin" htmlFor="middleName">Middle Name: </label>
                <input
                    className= "inputs"
                    type = {"text"}
                    id= {"middleName"}
                    label = {"Middle Name"}
                    placeholder = {"middle Name"}
                    variant = {"outlined"}
                    value = {middleName}
                    onChange= {(e)=> setMiddleName(e.target.value)}
                />
            
                <label className="labelmargin" htmlFor="lastName">Last Name: </label>
                <input
                    className= "inputs"
                    type = {"text"}
                    id= {"lastName"}
                    label = {"Last Name"}
                    placeholder = {"Last Name"}
                    variant = {"outlined"}
                    value = {lastName}
                    onChange= {(e)=> setLastName(e.target.value)}
                />

                <label className="labelmargin" htmlFor="phoneNumber">Phone Number: </label>
                <input
                    className= "inputs"
                    autoComplete="off"
                    type = {"tel"}
                    id= {"phoneNumber"}
                    label = {"Mobile Phone Number"}
                    placeholder = {"0912345678"}
                    variant = {"outlined"}
                    value = {phoneNumber}
                    onChange= {(e)=> setPhoneNumber(e.target.value)}
                />
            
                <label className="labelmargin" htmlFor="pwd">Create Password: </label>
                <input
                    className= "inputs"
                    type = {"password"}
                    id= {"pwd"}
                    label = {"password"}
                    placeholder = {"make strong password"}
                    variant = {"outlined"}
                    value = {pwd}
                    onChange= {(e)=> setPwd(e.target.value)}
                />
                
                <label className="labelmargin" htmlFor="matchPwd">Confirm Password: </label>
                <input
                    className= "inputs"
                    type = {"password"}
                    id= {"matchPwd"}
                    label = {"confirmPassword"}
                    placeholder = {"retype the password"}
                    variant = {"outlined"}
                    value = {matchPwd}
                    onChange= {(e)=> setMatchPwd(e.target.value)}
                />
                
                
                <Button
                    className= 'buttons'
                    onClick = {(e) => { handlePwds(e)}}
                >
                    Sign up
                </Button>

                <Button
                    className= 'buttons'
                    onClick = {(e) => { navigate('/customer/login') }}
                >
                    <i>already have one? </i> <b>Login</b>
                </Button>
                
                <BackButton className= 'buttons'/>
            </form>
            </section>
            {/* <Ads1 /> */}
        </section>
    );
}
export default Signup;