// import * as React from 'react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {Box,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,
  TableSortLabel,Toolbar,Typography,Paper,Checkbox,IconButton,Tooltip,FormControlLabel,Switch} from '@mui/material';
import {Edit,FilterList,AddCircleOutlined} from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { API_URL } from '../../Constants';
import BackButton from '../../BackButton';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export let request="";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) { return -1;}
  if (b[orderBy] > a[orderBy]) { return 1;}
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'? (a, b) => descendingComparator(a, b, orderBy):
  (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) { return order;}
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { headCells, order, orderBy,onRequestSort}=props;
  const createSortHandler = (property) => (event) => onRequestSort(event, property);
  return (
    <TableHead>
      <TableRow>
        < TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style = {{ backgroundColor: 'beige'}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { selected, tableTitle, numSelected} = props;
  const navigate = useNavigate();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          Request id = {selected}
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {tableTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Edit" style={{ margin: "0.5rem" }}>
            <IconButton onClick={() => navigate('/request/edit')} aria-label="edit the selected request">
              <Edit />
            </IconButton>
          </Tooltip>
        </>
      ) : (
      <>
      <Tooltip title="Add Request" style={{ margin: "0.5rem" }}>
        <IconButton onClick={() => navigate('/request/post')} aria-label="add request">
          <AddCircleOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Filter list">
        <IconButton>
          <FilterList />
        </IconButton>
      </Tooltip>
      </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {selected: PropTypes.string,numSelected: PropTypes.number.isRequired,};

const RequestListTable = ({ customer, tableTitle }) =>{
  const navigate = useNavigate();
  const id = customer? customer._id: "";
  const END_POINT = customer? `/request/my-requests/${id}` : '/request/list';
  const [data, setData] = React.useState([]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

useEffect(()=> {
  axios.get(API_URL + END_POINT)
  .then((res) => setData(res.data))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  .catch((err) => err)}, []);

  const headCells = [
    // { id: 'oid', numeric: false, disablePadding: true, label: 'O_id (MongoDB determined)' },
    { id: 'rollNo', numeric: false, disablePadding: true, label: 'No:' },
    { id: 'debitedBank', numeric: false, disablePadding: false, label: 'Deducted Bank' },
    { id: 'accountNumber', numeric: false, disablePadding: false, label: 'Account Num' },
    { id: 'atmOwnerBank', numeric: false, disablePadding: false, label: 'Committed Bank' },
    { id: 'debitedAmount', numeric: true, disablePadding: false, label: 'Debited Amount' },
    { id: 'cardNumber',numeric: false,disablePadding: false,label: 'Card Num/ (Tel)'},
    { id: 'cardBasedTxn', numeric: false, disablePadding: false, label: 'Trxn Type' },
    { id: 'debitedDate', numeric: false, disablePadding: true, label: 'Trxn Date' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Requested Date' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Request Status' },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleClick = (event, row) =>{
    if(selected){setSelected(""); request ="";}
    else {request = row; setSelected(row._id)}
  };
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {setRowsPerPage(parseInt(event.target.value, 10));setPage(0);}
  const handleChangeDense = (event) => setDense(event.target.checked);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  // setData(apiData);
  let rollNo=0;
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar tableTitle={tableTitle} numSelected={selected.length} selected={selected} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              selected={selected}
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  rollNo++;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id} aria-checked={isItemSelected}
                      onClick={(event) => handleClick(event, row)} selected={isItemSelected}
                    >
                      <TableCell padding="none"> 
                        <Checkbox padding="none" color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }}/>
                      </TableCell>
                      <TableCell align="center">{rollNo}</TableCell>
                      <TableCell align="center">{row.debitedBank}</TableCell>
                      <TableCell align="center">{row.accountNumber}</TableCell>
                      <TableCell align="center">{row.committedBank}</TableCell>
                      <TableCell align="center">{row.debitedAmount}</TableCell>
                      {
                        row.trxnType === "Card Trxn" ? (<TableCell align="center"> {row.cardNumber} </TableCell>):
                        (<TableCell align = "center" > {row.phoneNumber} </TableCell>)
                      }
                      <TableCell align="center">{row.trxnType}</TableCell>
                      <TableCell align="center">{row.debitedDate}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows,}} >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <BackButton/>
      <Button onClick={()=>navigate('/request/post')}>Add Request</Button>
    </Box>
  );
}
export default RequestListTable;