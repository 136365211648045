import React from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RequestEdit from './RequestEdit';
import {request} from '../request/reqTables/RequestListTable';
import { customer } from '../customer/Login';

const RequestEditLayer = () => {
  const navigate = useNavigate();
  
  return (
    <>
    {
      !request? (
        <section className='section' >
          <h2 
            style={{backgroundColor:'InfoText',
            border: '2px solid InfoText',
            borderRadius:'0.5rem'}}
          >
            Can not Access your Identity !! <br/> Please Login First. {" "}
            <Button onClick={()=>navigate('/customer/login')}> Go Login </Button>
          </h2>
        </section>):
      (
        
        <RequestEdit request={request} customer ={customer} />
      )
    }
    </>
  )
}

export default RequestEditLayer