import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import { Button} from 'react-bootstrap';
import "../css/inputs.css";
import { API_URL } from '../Constants';
import '../css/customer.css';

import BackButton from "../BackButton";

// const NAME_REGEX = /^[A-z]$/;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CustomerEdit = ({customer}) => { //customer is an object

    const navigate = useNavigate();
    const [firstName, setFirstName] = useState(customer.firstName);
    const [middleName, setMiddleName] = useState(customer.middleName);
    const [lastName, setLastName] = useState(customer.lastName);
    // const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumber);
    const [pwd, setPwd] = useState("");

    function handleSubmit(e){
      e.preventDefault(e); //editting goto customer-route
      const id = customer._id;
        axios.put(API_URL + `/customer/edit/${id}`, { id, firstName, middleName, lastName })
      .then((res) => {
          if(res.data.message){
            alert("Info Updated Successfully !");
            navigate(-1);
          }
          else alert(res.data.message)
      }).catch((err) => {
        if(err.response.data.message) alert(err.response.data.message);
        else alert(err)
      })
    }


  return(
    <section className="section">
        <form className="form">
            <h4 className="formh4"> Edit Your Profile </h4>

            <label htmlFor="firstName">First Name: </label>
            <input
                className="inputs"
                type = {"text"}
                id={"firstName"}
                label = {"First Name"}
                placeholder = {"First Name"}
                variant = {"outlined"}
                value = {firstName}
                onChange={(e) => setFirstName(e.target.value)}
            />
            
            <label htmlFor="middleName">Middle Name: </label>
            <input
                className= "inputs"
                type = {"text"}
                id= {"middleName"}
                label = {"Middle Name"}
                placeholder = {"middle Name"}
                variant = {"outlined"}
                value = {middleName}
                onChange= {(e)=> setMiddleName(e.target.value)}
            />
        
            <label htmlFor="lastName">Last Name: </label>
            <input
                className= "inputs"
                type = {"text"}
                id= {"lastName"}
                label = {"Last Name"}
                placeholder = {"Last Name"}
                variant = {"outlined"}
                value = {lastName}
                onChange= {(e)=> setLastName(e.target.value)}
            />

            {/* <label htmlFor="phoneNumber">Phone Number: </label>
            <input
                className= "inputs"
                autoComplete="off"
                type = {"tel"}
                id= {"phoneNumber"}
                label = {"Mobile Phone Number"}
                placeholder = {"0912345678"}
                variant = {"outlined"}
                value = {phoneNumber}
                onChange= {(e)=> setPhoneNumber(e.target.value)}
            /> */}
            
            <label htmlFor="pwd">Password: </label>
            <input
                className= "inputs"
                type = {"password"}
                id= {"pwd"}
                label = {"password"}
                placeholder = {"confirm the change with password"}
                variant = {"outlined"}
                value = {pwd}
                onChange= {(e)=> setPwd(e.target.value)}
            />
            <Button className= 'buttons' onClick = {(e) => handleSubmit(e)}>
                Update
            </Button>
            <Button className= 'buttons' onClick = {(e) => handleSubmit(e)}>
                <i>forget password?</i> Reset
            </Button>
            <BackButton />
        </form>
    </section>
  );
}

export default CustomerEdit