import React, { useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';

import "../css/inputs.css";
import { API_URL } from '../Constants';
import BackButton from '../BackButton';
import { useNavigate } from 'react-router-dom';

export const banksOption = [
  {id: "selectone", label: "select one"},
  {id: 'commercial-bank-of-ethiopia', label: 'CBE', value: 'Commercial Bank of Ethiopia',},
  {id: 'berhan-bank',label: 'Berhan Bank',value: 'Berhan Bank',},
  {id: 'dashen-bank',label: 'Dashen Bank',value: 'Dashen Bank',},
  {id: 'oromia-bank',label: 'Oromia Bank',value: 'Oromia Bank',},
  {id: 'amhara-bank',label: 'Amhara Bank',value: 'Amhara Bank',},
  {id: 'debub-global-bank',label: 'Debub Global Bank',value: 'Debub Global Bank',},
  {id: 'oromia-cooperative-bank',label: 'COOP',value: 'Oromia Cooperative Bank',},
  {id: 'awash-bank',label: 'Awash Bank',value: 'Awash Bank',},
  {id: 'abbysinia-bank',label: 'Abbysinia Bank',value: 'Abbysinia Bank',},
  {id: 'hibret-bank',label: 'Hibret Bank',value: 'Hibret Bank',},
];

const RequestEdit = ({customer, request}) => {

  const navigate = useNavigate();
  const {_id} = customer;
  const [debitedBank, setDebitedBank] = React.useState(request.debitedBank);
  const [committedBank, setCommittedBank] = React.useState(request.committedBank);
  const [cardNumber, setCardNumber] = React.useState(request.cardNumber);
  const [debitedDate, setDebitedDate] = React.useState(request.debitedDate);

  const [checked, setChecked] = React.useState(false);
  const [debitedAmount, setDebitedAmount] = React.useState(request.debitedAmount);
  const [phoneNumber, setPhoneNumber] = React.useState(request.phoneNumber);
  const [accountNumber, setAccountNumber] = React.useState(request.accountNumber);
  const [cardBasedTxn, setCardBasedTxn] = React.useState(request.trxnType === "Card Trxn"? true: false);

  const [trxnType, setTrxnType] = React.useState(cardBasedTxn? 'Card Trxn' : 'Mobile Trxn');

  useEffect(()=> {setTrxnType(cardBasedTxn? 'Card Trxn' : 'Mobile Trxn')},[cardBasedTxn])
  
  const handleCheckForFields = () => {

    const isAllGood = cardBasedTxn ? (_id&&debitedBank&&cardNumber&&committedBank&&debitedDate&&
      accountNumber&& !(debitedAmount === "00" || debitedAmount === "")):
      (_id&&debitedBank&&phoneNumber&&committedBank&&accountNumber&&debitedDate&&
      !(debitedAmount === "00" || debitedAmount === ""));

    if(isAllGood && checked) setChecked(false);
    else if(isAllGood) setChecked(true)
    else { setChecked(false); alert("fields cannot be empty")}
  }

  const handleSubmit = () => {
    const postData = cardBasedTxn ? ({id:_id,debitedBank,cardNumber,accountNumber,committedBank,
      debitedDate,debitedAmount,trxnType}):({id:_id,debitedBank,phoneNumber,accountNumber,committedBank,
      debitedDate,debitedAmount,trxnType});

    if(checked){
      axios
        .put(API_URL + `/request/edit/${request._id}`, postData)
      .then(res => {
        alert("sorry that "+ res.data.message.committedBank + " wrongly debited ETB: " + 
        res.data.message.debitedAmount + " from your " + res.data.message.debitedBank +
        " account! \n ------------------------------------\n Request is successfully sent");
        navigate(-1);
      })
      .catch(err => alert(err));
    }
    else {
      alert("Please make sure the checkbox is checked!!")
    }
  }

  const handleTransaction = () => setCardBasedTxn(!cardBasedTxn);

  return (
    <section className='section'>
      <form title='Fill your dispute request' className="form">
        <span style={{alignItems:'flex-end', display:'flex', flexDirection:'row'}}>
          <span style={{alignItems:'start'}}>
            <input
              id='card'
              type= {"checkbox"}
              checked= {cardBasedTxn}
              onChange= {handleTransaction}
            />
            {" "}
            <label className='labelmargin' htmlFor='card'>Card Transaction</label>
          </span>
          <span style={{alignItems:'end'}}>
            <input
              id='mobile'
              type= {"checkbox"}
              checked= {!cardBasedTxn}
              onChange= {handleTransaction}
            />
            {" "}
            <label htmlFor='mobile'> Mobile Transaction</label>
          </span>
        </span>

        <h4 className='formh4'> Request Edition Form </h4>
      
        <label className="labelmargin" htmlFor="debited-bank">Debited Bank: </label>
        <select
          style={{border: "2px solid gray"}}
          className= "inputs"
          id="debited-bank"
          required
          variant={'outlined'}
          label="Debited Bank"
          value={debitedBank}
          onChange={(e) => setDebitedBank(e.target.value)}
        >
          {banksOption.map((option)=>(<option key={option.id} value={option.value}> {option.label}</option>))}
        </select>
        <label className="labelmargin" htmlFor="accountNumber">Account Number: </label>
        
        <input
          className= "inputs"
          type = {"Number"}
          id= {"accountNumber"}
          required
          placeholder = {"account number for your deducted bank"}
          variant = {"outlined"}
          value = {accountNumber}
          onChange= {(e)=>setAccountNumber(e.target.value)}
        />

        {
          cardBasedTxn? (
          <>
            <label className="labelmargin" htmlFor="card-number">Card Number: </label>
            <input
              className= "inputs"
              id="card-number"
              type={"number"}
              required
              variant={'outlined'}
              placeholder="see the back of your card"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </>):(
          <><label className="labelmargin" htmlFor="phoneNumber">Phone Number: </label>
            <input
              className= "inputs"
              type = {"tel"}
              id= {"phoneNumber"}
              required
              placeholder = {"the phone number you used for the transaction"}
              variant = {"outlined"}
              value = {phoneNumber}
              onChange= {(e)=>setPhoneNumber(e.target.value)}
            />
          </>)
        }

        <label className="labelmargin" htmlFor="atm-owner-bank">Committed Bank: </label>
        <select
          style={{border: "2px solid gray"}}
          className= "inputs"
          type="text"
          id="committed-bank"
          required
          variant={'outlined'}
          value={committedBank}
          onChange={(e)=> setCommittedBank(e.target.value)}
        >
          {banksOption.map((option)=>(<option key={option.id} value={option.value}>{option.label}</option>))}
        </select>

        <label className="labelmargin" htmlFor="debited-date">Debited Date: </label>
        <input
          className= "inputs"
          id="debited-date"
          type="date"
          variant={'outlined'}
          required
          value={debitedDate}
          onChange={(e) => setDebitedDate(e.target.value)}
        />

        <label className="labelmargin" htmlFor="amount-debited">Debited Amount: </label>
        <input
          className= "inputs"
          id= "amount-debited"
          type= {"number"}
          required
          value= {debitedAmount}
          variant={'outlined'}
          onChange= {(e) => setDebitedAmount(e.target.value)}
        />
        <span>
          <input type= {"checkbox"} checked= {checked} onChange= {handleCheckForFields}/>{" "}
          <label style={{display:"inline"}} onDoubleClick= { handleCheckForFields}>
            I agree that the above informations are correct
          </label>
        </span>

        <Button  className="buttons" onClick= {handleSubmit}>
          Submit Request
        </Button>
        <BackButton />
      </form>
    </section>
  );
}
export default RequestEdit;