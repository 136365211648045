import React from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomerEdit from './CustomerEdit';
import {customer} from './Login'

const CustomerEditLayer = () => {
  const navigate = useNavigate();
  return (
    <>
    {
      !customer? (
        <section className='section' >
          <h2 
            style={{backgroundColor:'InfoText',
            border: '2px solid InfoText',
            borderRadius:'0.5rem'}}
          >
            Can not Access your Identity !! <br/> Please Login First. {" "}
            <Button onClick={()=>navigate('/customer/login')}>Go Login</Button>
          </h2>
        </section>):
      (
        <CustomerEdit customer={customer} />
      )
    }
    </>
  )
}

export default CustomerEditLayer