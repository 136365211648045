import React from 'react';
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import './components/css/App.css';
import './components/css/inputs.css'
import Navbar from './components/Navbar';

function App() {
  const navigate = useNavigate();
  return (
    <>
    <Navbar />
    
    <div className="App">
      <h1 style={{color: "green"}}>WELCOME </h1>
      <h1 style={{color: "yellow"}}> <b>to</b></h1>
      <h1 style={{color: "red"}}>Dispute Management System</h1>

      <div >
        <Button className='buttons' onClick = {()=>navigate('/customer/signup')}> SIGNUP </Button>
        <Button className='buttons' onClick = {()=>navigate('/customer/login')}> LOGIN </Button>
        <Button className='buttons' onClick = {()=>navigate('/customer/list')}> Cust list </Button>
      </div>
    </div>
    </>
  );
}

export default App;
