import React from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import { Button} from 'react-bootstrap';
import BackButton from "../BackButton";

import "../css/inputs.css";
import { API_URL } from '../Constants';
export let customer;

const Login = () => {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [pwd, setPwd] = React.useState("");

    function handleSubmit(e){
        if(phoneNumber && pwd){
            e.preventDefault();
            axios.post(API_URL + '/customer/login', { phoneNumber, pwd })
            .then((res) => { customer = res.data.message; navigate('/customer');
            }).catch((err) => alert(err.response.data))
        }
        else alert("fields cannot be empty !!")
    }

    return(
        <section className="section">
            <form className="form">
                <h4 className="formh4">  
                    Login Form
                </h4>

                <label className="labelmargin" htmlFor="phoneNumber">Phone Number: </label>
                <input
                    className="logininputs"
                    type = {"tel"}
                    id={"phoneNumber"}
                    required ={true}
                    label = {"Mobile Phone Number"}
                    placeholder = {"0912345678"}
                    variant = {"outlined"}
                    value = {phoneNumber}
                    onChange={(e)=> setPhoneNumber(e.target.value)}
                />

                <label className="labelmargin" htmlFor="pwd">Login Password: </label>
                <input
                    className="logininputs"
                    type = {"password"}
                    id={"pwd"}
                    required ={true}
                    label = {"password"}
                    placeholder = {"login password"}
                    variant = {"outlined"}
                    value = {pwd}
                    onChange={(e)=> setPwd(e.target.value)}
                />
            
                <Button className= 'buttons' onClick = {(e)=> handleSubmit(e)}>
                    Log in
                </Button>
                <Button className= 'buttons' onClick = {(e)=> navigate('/customer/signup')}>
                   <i>Don't have account? </i><b>Signup</b> 
                </Button>
                <BackButton className= 'buttons'/>
            </form>
        </section>
    );
}
export default Login